<template>

  <HelloWorld/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  created () {
    document.title = "Vocabulary to Audio Converter";
      
  }
}
</script>

<style>
body{
    
  background: rgba(172, 169, 187,0.2);
    margin: 0px;
    padding: 0px;
}
</style>
