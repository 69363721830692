<template>
  <div>
      
      <div class="grid-container">
          <div class="grid-item grid-item-1"><h1 class="h1a">Vocab Listening  &nbsp; &nbsp;| &nbsp; &nbsp; Vocabulary to Audio Converter</h1></div><!--<h1 class="h1a">Audio Listening</h1>-->
          
          
          <div class="grid-item grid-item-2">
            
              <div class="input-grid-1" id="input-grid-1-1">
                  
                  <label for="csvFile" class="btn-item btncsv btncsv1">
                    <img class="upload" src="../assets/upload.png">
                    
                    
                    
                  </label> 
                  <label for="csvFile" class="btn-item btncsv btncsv2">
                    <h3 class="textselect btntextelement">Select csv</h3>
                    
                    
                    
                  </label>     
              </div>
              
              <input style="opacity:0;" class="btn-item btncsv input-grid-2" type="file" id="csvFile" accept=".csv"/>
          </div>
          <!--<div class="grid-item grid-item-2"><button class="btn-item btncsv">Select csv File</button></div>-->
          <div class="grid-item grid-item-3" id="grid-item-youtube">
            <iframe id="youtubevideo"  src="https://www.youtube.com/embed/tBIo4f7LJzE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <!--<img class="image3" src="../assets/Image3.png">-->
          </div><!--<img src="./assets/logo.png">-->
          <div class="grid-item grid-item-4" id="grid-item-4-4">
            <div >
              <div id="table">
                <table id="entiretable" class="entiretable">
                  <thead id="thead">
                      <tr>
                      <th>
                          <select id="LanguageInput" class="btn-item">
                              <option selected="selected" value="German">German</option>
                              <option value="English">English</option>
                              <option value="French">French</option>
                              
                          </select>
                      </th>
                      <th>
                          <select id="LanguageOutput" class="btn-item">
                              <option value="German">German</option>
                              <option selected="selected" value="English">English</option>
                              <option value="French">French</option>
                              
                          </select>
                      </th>
                      </tr>
                  </thead>  
                </table>
                
              </div>
            </div>
            
          </div>
          <div class="grid-item grid-item-5">
              <h3 id="slider1-h3">Break after German</h3>
              <div class="slider1">
                  
                  <input type="range" min="0" max="5" value="1" id="fader" 
                  step="1" list="volsettings" oninput="rangeValue1.innerText = this.value">
                  <datalist id="volsettings">
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      
                  </datalist>
                  <p id="rangeValue1">1</p><span>s</span>

              </div>

          
          </div>
          <div class="grid-item grid-item-6">
              <h3 id="slider2-h3">Break after English</h3>
              <div class="slider2">
                  
                  <input type="range" min="0" max="5" value="2" id="fader" 
                  step="1" list="volsettings" oninput="rangeValue2.innerText = this.value">
                  <datalist id="volsettings">
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      
                  </datalist>
                  <p id="rangeValue2">2</p><span>s</span>

              </div>
              

          </div>
          <div class="grid-item grid-item-7">
              <h3 id="slider3-h3">German Voice Speed</h3>
              <div class="slider3">
                  
                  <input type="range" min="50" max="100" value="90" id="fader" 
                  step="10" list="volsettings" oninput="rangeValue3.innerText = this.value">
                  <datalist id="volsettings">
                      <option>50</option>
                      <option>60</option>
                      <option>70</option>
                      <option>80</option>
                      <option>90</option>
                      <option>100</option>
                      
                  </datalist>
                  <p id="rangeValue3">90</p><span>&#37;</span>

              </div>


          </div>
          <div class="grid-item grid-item-8">
              <h3 id="slider4-h3">English Voice Speed</h3>
              <div class="slider4">
                  
                  <input type="range" min="50" max="100" value="90" id="fader" 
                  step="10" list="volsettings" oninput="rangeValue4.innerText = this.value">
                  <datalist id="volsettings">
                      <option>50</option>
                      <option>60</option>
                      <option>70</option>
                      <option>80</option>
                      <option>90</option>
                      <option>100</option>
                      
                  </datalist>
                  <p id="rangeValue4">90</p><span>&#37;</span>

              </div>


          </div>
          <!--
          <div class="grid-item grid-item-9">
            <button class="btn-item btntestsettings" id ="test">
              
                <img  src="../assets/upload.png">
              
              
              Test Settings
            
          </button>
          </div>
        -->

        
        <div class="grid-item grid-item-9">
            <div class=" input-grid-3" id ="test">
              <img class="btn-item  btncsv1" src="../assets/audio.png">
                <h3 class="btn-item btncsv2 testsettingtext">Test Settings</h3>
              
                
                
            
            
            
            </div>
        </div>
        
          <div class="grid-item grid-item-10">
            <div class=" input-grid-4" id ="test1">
              <img class="btn-item  btncsv1" src="../assets/download.png">
                <h3 class="btn-item btncsv2 testsettingtext">Download</h3>
              
                
                
            
            
            
            </div>
          </div>
      </div>


  </div>
</template>

<script>
export default {
  




  mounted(){
    let youtubegridheight=document.getElementById("grid-item-youtube").clientHeight ||document.getElementById("grid-item-youtube").offsetHeight
    let youtubegridwidth =document.getElementById("grid-item-youtube").clientWidth  ||document.getElementById("grid-item-youtube").offsetWidth
    if (youtubegridheight<youtubegridwidth/2.5){
      youtubegridwidth=2.5*youtubegridheight
    }else if(youtubegridheight>youtubegridwidth/1.78){
          youtubegridheight=youtubegridwidth/1.78
        }
    document.getElementById("youtubevideo").height= youtubegridheight-10 +"px"
    document.getElementById("youtubevideo").width= youtubegridwidth-20+"px"
            


    var AWS = require("aws-sdk");
      var JSZip = require("jszip")
      var FileSaver = require('file-saver')
      
      let urlcollection =[]
      urlcollection[0]=[]
      urlcollection[1]=[]





      let tableextractionresult;
      let tableextraction=[];
      
      var blobarray=[]

      let data_csv;
      let data_csv_pred;
      
      //read csv


      let newlist =[];
      let newtestlist=[];
      
      const zip = new JSZip();
      //const img = zip.folder("images");
      //let data;
      //const myForm= document.getElementById("myForm");
      const csvFile = document.getElementById("csvFile");
      //get mp3
      var paramtextinput;
      var paramtext;
      document.getElementById("test").addEventListener("click",testprint)
      document.getElementById("test1").addEventListener("click",testprint1)
      //testing parameters
      let testvoicegerman = ["Lernen", "macht", "viel Spass"]
      let testvoiceenglish = ["Learning", "makes","a lot of fun"]
      let testvoicefrench = ["apprendre", "fait", "beaucoup de joie"]
      let testblobcollection =[]
      async function testprint(){
        
          
        function ttspolly1_test(ii,roww,voicevoice,breaktime,speed){
          paramtextinput=ii;
          paramtext= "<speak>"+breaktime+speed+paramtextinput+"</prosody></speak>"
          //params.Text = data[rown][ii]
          params.Text = paramtext
          params.VoiceId = voicevoice
          return new Promise((resolve) => {
            polly.synthesizeSpeech(params,function(err,data){
            if(err){
            console.log(err,err.stack)
            }else{
            let uInt8Array = new Uint8Array(data.AudioStream);
            let arrayBuffer = uInt8Array.buffer;
            let blob = new Blob([arrayBuffer], { type: "audio/mpeg" });
            //let blob = new Blob([arrayBuffer]);
            //testblobcollection[roww]=blob
            //console.log(roww)
            resolve(blob);
            }
          })
              
              
          });
              
        }
        
        
        var betweenbreak = "<break time='"+document.getElementById("rangeValue1").innerText+"s'/>";
        var afterbreakt = "<break time='"+document.getElementById("rangeValue2").innerText+"s'/>";
        var inspeed = "<prosody rate='"+document.getElementById("rangeValue3").innerText+"%'>";
        var outspeed = "<prosody rate='"+document.getElementById("rangeValue4").innerText+"%'>";
        
        if (voicein=="Marlene"){
            
            testblobcollection[0]= await ttspolly1_test(testvoicegerman[0],0,voicein,afterbreakt,inspeed)
            testblobcollection[2]= await ttspolly1_test(testvoicegerman[1],2,voicein,afterbreakt,inspeed)

            testblobcollection[4]= await ttspolly1_test(testvoicegerman[2],4,voicein,afterbreakt,inspeed)
        } else if(voicein=="Emma"){
            testblobcollection[0]= await ttspolly1_test(testvoiceenglish[0],0,voicein,afterbreakt,inspeed)
            testblobcollection[2]= await ttspolly1_test(testvoiceenglish[1],2,voicein,afterbreakt,inspeed)

            testblobcollection[4]= await ttspolly1_test(testvoiceenglish[2],4,voicein,afterbreakt,inspeed)
        } else if(voicein=="Lea"){
            testblobcollection[0]= await ttspolly1_test(testvoicefrench[0],0,voicein,afterbreakt,inspeed)
            testblobcollection[2]= await ttspolly1_test(testvoicefrench[1],2,voicein,afterbreakt,inspeed)

            testblobcollection[4]= await ttspolly1_test(testvoicefrench[2],4,voicein,afterbreakt,inspeed)
        }
        if (voiceout=="Marlene"){
            testblobcollection[1]= await ttspolly1_test(testvoicegerman[0],1,voiceout,betweenbreak,outspeed)
            testblobcollection[3]= await ttspolly1_test(testvoicegerman[1],3,voiceout,betweenbreak,outspeed)

            testblobcollection[5]= await ttspolly1_test(testvoicegerman[2],5,voiceout,betweenbreak,outspeed)
        } else if(voiceout=="Emma"){
            testblobcollection[1]= await ttspolly1_test(testvoiceenglish[0],1,voiceout,betweenbreak,outspeed)
            testblobcollection[3]= await ttspolly1_test(testvoiceenglish[1],3,voiceout,betweenbreak,outspeed)

            testblobcollection[5]= await ttspolly1_test(testvoiceenglish[2],5,voiceout,betweenbreak,outspeed)
        } else if(voiceout=="Lea"){
            testblobcollection[1]= await ttspolly1_test(testvoicefrench[0],1,voiceout,betweenbreak,outspeed)
            testblobcollection[3]= await ttspolly1_test(testvoicefrench[1],3,voiceout,betweenbreak,outspeed)

            testblobcollection[5]= await ttspolly1_test(testvoicefrench[2],5,voiceout,betweenbreak,outspeed)
        }
        let bblobtest = new Blob(testblobcollection, { type: "audio/mpeg" })
        let uurl = URL.createObjectURL(bblobtest); 
        let audiourlcomb = new Audio(uurl);
        audiourlcomb.play()
                  
              
              
          
      }
      const ALIAOYMFICM5P6IS96MW="AKIAWYMFMCM5P6IS56MW";
      const L2L9PlI2tNZ95x7LDAyNt3cP9SVmCqUdLORAI6N= "L2LtPlT2tCZ95/x7LDAyNt3cG9SVmCqGdLERAI6N";



      //let t_headelement = document.getElementById("thead");

      //let maxheight;
      //let maxwidth;
      let maxheight=document.getElementById("grid-item-4-4").clientHeight-10+"px" || document.getElementById("grid-item-4-4").offsetHeight-10+"px"
      
      let maxwidth= document.getElementById("grid-item-4-4").clientWidth-10+"px" ||document.getElementById("grid-item-4-4").offsetWidth-10+"px"
      


      let theadheight;
      //csv to audio
      function notify() { 
        
        maxheight=document.getElementById("grid-item-4-4").clientHeight-10+"px" || document.getElementById("grid-item-4-4").offsetHeight-10+"px";
        
        maxwidth= document.getElementById("input-grid-1-1").clientWidth-10+"px" ||document.getElementById("input-grid-1-1").offsetWidth-10+"px";
        
        let t_headelement = document.getElementById("thead")
        t_headelement.style.maxHeight=maxheight
        
        t_headelement.style.maxWidth=maxwidth
        
        theadheight = document.getElementById("thead").clientHeight+"px" ||document.getElementById("thead").offsetHeight+"px"

        if(document.getElementById("tbody")){
          let t_bodyelement = document.getElementById("tbody");
          //t_bodyelement.style.paddingRight = "50px"
          t_bodyelement.style.maxHeight=maxheight.replace("px","")-theadheight.replace("px","")+"px";
          
          //new2
         // t_bodyelement.style.maxWidth=maxwidth;
          t_bodyelement.style.width=maxwidth;

            
        } 
        


        var xxx = document.getElementsByClassName('colourodd');
        for(var i=0; i< xxx.length;i++){
          
            //xxx[i].style.width = maxwidth;
            xxx[i].style.width =(maxwidth.replace("px",""))-30+"px";
            
        }
        var yxx = document.getElementsByClassName('coloureven');
        for(var iy=0; iy< yxx.length;iy++){
            //newnew
            //yxx[iy].style.width = maxwidth;
            yxx[iy].style.width =(maxwidth.replace("px",""))-30+"px";
            
            
        }

        youtubegridheight=document.getElementById("grid-item-youtube").clientHeight ||document.getElementById("grid-item-youtube").offsetHeight
        youtubegridwidth =document.getElementById("grid-item-youtube").clientWidth  ||document.getElementById("grid-item-youtube").offsetWidth
        if (youtubegridheight<youtubegridwidth/2.5){
          youtubegridwidth=2.5*youtubegridheight
        }else if(youtubegridheight>youtubegridwidth/1.78){
          youtubegridheight=youtubegridwidth/1.78
        }
        
        document.getElementById("youtubevideo").height= youtubegridheight-10 +"px"
        document.getElementById("youtubevideo").width= youtubegridwidth-20+"px"


        for (let iloop = 1; iloop < rownumber; iloop++) {
              
              document.getElementById("table"+iloop+""+0).style.width=(maxwidth.replace("px","")-30)/2+"px";
              document.getElementById("table"+iloop+""+1).style.width=(maxwidth.replace("px","")-30)/2+"px";
              
            }
      }
      window.addEventListener("resize", notify)
      

      document.getElementById("csvFile").onchange = async function() { 
        //if(document.getElementById("entiretable")){
          if(document.getElementById("tbody")){
            //data=[];
            newtestlist=[];
            newlist =[];
            data_csv=[]
            data_csv_pred=[];
            //document.getElementById("entiretable").remove()
            document.getElementById("tbody").remove()
            //new3
            rownumber=1;
            
        } 
        data_csv= await readcsvtest()
     

        for (let inn=0;inn<data_csv[0].length;inn++){
            newtestlist[inn]=[]
            newtestlist[inn][0]=data_csv[0][inn]
            newtestlist[inn][1]=data_csv[1][inn]
            newlist[inn]=Object.assign({}, newtestlist[inn])
            
            
        }

        addtable()
        };

        let myTable = document.querySelector('#table');
        let rownumber = 1;


        function addtable(){
            var bodycount=[1,0]
            
            let table =document.getElementById("entiretable")
            
            let tbody= table.createTBody()
            tbody.setAttribute('id','tbody');
            tbody.style.display = "flex";
            tbody.style.justifyContent="center";
          
            newlist.forEach(emp=>{
                
                let row = document.createElement('tr')
                if (rownumber%2==0){
                        row.setAttribute("class", "coloureven");
                        //row.setAttribute("id", "coloureven");
                    } else if(rownumber%2==1){
                        row.setAttribute("class", "colourodd");
                        //row.setAttribute("id", "colourodd");
                    }
                Object.values(emp).forEach(text =>{
                    let cell = document.createElement('td');
                    
                    let newinputbox = document.createElement("input");
                    newinputbox.setAttribute("type", "text");
                    newinputbox.setAttribute("class", "tabletextinput");


                    newinputbox.setAttribute("id", "tabletextinput");

                    newinputbox.setAttribute('id','table'+bodycount[0]+bodycount[1])
                    newinputbox.style.backgroundColor="rgba(172, 169, 187,0.2)"
                    newinputbox.style.border="none"
                    newinputbox.style.borderRadius="5px"
                    newinputbox.style.textAlign="center"
                    
                    
                    bodycount[1]+=1
                    newinputbox.value = text;
                    
                    cell.appendChild(newinputbox);
                    row.appendChild(cell) 
                    //console.log(text)
                    
                })
                tbody.appendChild(row)
                bodycount[0]+=1
                bodycount[1]=0
                rownumber+=1
            })
            table.appendChild(tbody)
            myTable.appendChild(table)
            let t_headelement = document.getElementById("thead");
            let t_bodyelement = document.getElementById("tbody");
            //maxheight=document.getElementById("grid-item-4").clientHeight-10+"px" || document.getElementById("grid-item-4").offsetHeight-10+"px"
            
            //maxwidth= document.getElementById("grid-item-4").clientWidth-10+"px" ||document.getElementById("grid-item-4").offsetWidth-10+"px"
            
            
          
            
            t_headelement.style.overflow='auto';
            t_headelement.style.maxHeight=maxheight;

            t_headelement.style.maxWidth=maxwidth;


            //new1
            theadheight = document.getElementById("thead").clientHeight+"px" ||document.getElementById("thead").offsetHeight+"px"


            t_bodyelement.style.display= 'block';

            //t_bodyelement.style.margin= 'auto';
            t_bodyelement.style.overflow='auto';
            t_bodyelement.style.maxHeight=maxheight.replace("px","")-theadheight.replace("px","")+"px";
           //new2
           t_bodyelement.style.width=maxwidth;
            //t_bodyelement.style.maxWidth=maxwidth;
            var xxx = document.getElementsByClassName('colourodd');
            //console.log(maxwidth.replace("px","")/2+"px")
            for(var i=0; i< xxx.length;i++){
                //newnew
                xxx[i].style.width = maxwidth.replace("px","")-30+"px";
                //xxx[i].style.maxWidth = maxwidth;
                
                xxx[i].style.display = "flex";
                xxx[i].style.justifyContent="center";
                
            }
            var yxx = document.getElementsByClassName('coloureven');
            for(var iy=0; iy< yxx.length;iy++){
              //newnew
                yxx[iy].style.width = maxwidth.replace("px","")-30+"px";
                //yxx[iy].style.maxWidth = maxwidth;
                yxx[iy].style.display = "flex";
                yxx[iy].style.justifyContent="center";
                
            }
           
            for (let iloop = 1; iloop < rownumber; iloop++) {
              
              document.getElementById("table"+iloop+""+0).style.width=(maxwidth.replace("px","")-30)/2+"px";
              document.getElementById("table"+iloop+""+1).style.width=(maxwidth.replace("px","")-30)/2+"px";
            }
            
           
        }





      function csvToArray1(str) {
        
        //console.log(str.split("\n"))
        const rows = str.split("\n");
        let rawarr =[]
        let rawarrcounter = 0
        let separatorcount =[0,0]
        const arr = [];
        arr[0]=[];
        arr[1]=[]
        for (let i=0; i<rows.length;i++){
          //create array without empty lines
          if (rows[i]!="\r" && rows[i]!=""){
            rawarr[rawarrcounter]=rows[i].replace("\r","")
            //console.log(rows[rawarrcounter])
            //check each line for separator
            if(rawarr[rawarrcounter].search(";")!=-1){
              separatorcount[0]+=1
            }
            if(rawarr[rawarrcounter].search(",")!=-1){
              separatorcount[1]+=1
            }
            
            rawarrcounter+=1
            
          }else{
            console.log(" ")
          }
          
        }
        
        if (separatorcount[0]>rawarrcounter/2){
          
          for (let i=0; i<rawarrcounter;i++){
            arr[0][i]=rawarr[i].split(";")[0].replace("\r","")
            arr[1][i]=rawarr[i].split(";")[1].replace("\r","")
          }
        } else if (separatorcount[1]>rawarrcounter/2){
          for (let i=0; i<rawarrcounter;i++){
            arr[0][i]=rawarr[i].split(",")[0].replace("\r","")
            arr[1][i]=rawarr[i].split(",")[1].replace("\r","")
          }
        } else{
          for (let i=0; i<rawarrcounter;i++){
            //arr[i%2][Math.floor(i/2)]=rawarr[i].replace("\r","")
            arr[i%2][Math.floor(i/2)]=rawarr[i].replace("\r","").replace(/[&<>"']/g,'')
          }
        }
        //console.log("final")
        //console.log(arr)
        
        
        
        return arr;
      } 
      function readcsvtest(){
        
        //console.log("luck out for next")
        //console.log(csvFile.files[0].name.split(".")[0])
          const input = csvFile.files[0]
        
          const reader = new FileReader();
          return new Promise((resolve) => {
              reader.onload = function(e){
                  const text = e.target.result;
                  data_csv_pred = csvToArray1(text)
                  resolve(data_csv_pred)
              }
              reader.readAsText(input,'ISO-8859-1')
          });
          
      }









      async function ttspolly1(){ 
        return new Promise((resolve) => {
          polly.synthesizeSpeech(params,function(err,data){
            if(err){
            console.log(err,err.stack)
            }else{
            let uInt8Array = new Uint8Array(data.AudioStream);
            let arrayBuffer = uInt8Array.buffer;
            let blob = new Blob([arrayBuffer], { type: "audio/mpeg" });
            
            resolve(blob)
            }
          })
        });     
      }
      
      function extractdata(tableextraction){
        for (let ii=1;ii<=data_csv[0].length;ii++){
          for (let jj=0;jj<2;jj++){
            
            //console.log(document.getElementById("table"+ii+jj).value)
            tableextraction[jj][ii-1]=document.getElementById("table"+ii+jj).value

            
          }
          
        }
        return tableextraction
      }
      
      async function testprint1(){
        tableextractionresult=[]
          
          tableextraction[0]=[]
          tableextraction[1]=[]
          tableextractionresult = extractdata(tableextraction);
          //console.log(tableextractionresult)
          var betweenbreak = "<break time='"+document.getElementById("rangeValue1").innerText+"s'/>";
          var afterbreakt = "<break time='"+document.getElementById("rangeValue2").innerText+"s'/>";
          var inspeed = "<prosody rate='"+document.getElementById("rangeValue3").innerText+"%'>";
          var outspeed = "<prosody rate='"+document.getElementById("rangeValue4").innerText+"%'>";
          for (let ii=0; ii<tableextractionresult[0].length;ii++){ 
              paramtextinput=tableextractionresult[0][ii];
              paramtext= "<speak>"+afterbreakt+inspeed+paramtextinput+"</prosody></speak>"
              params.Text = paramtext
              params.VoiceId = voicein
              
              urlcollection[0][ii] = await ttspolly1()
              paramtextinput=tableextractionresult[1][ii];
              paramtext= "<speak>"+betweenbreak+outspeed+paramtextinput+"</prosody></speak>"
              //params.Text = data[rown][ii]
              params.Text = paramtext
              params.VoiceId = voiceout
              urlcollection[1][ii] = await ttspolly1()
              
          }
          
          for (let j=0; j<urlcollection[0].length;j++){
              blobarray[j*2]=urlcollection[0][j]
              blobarray[j*2+1]= urlcollection[1][j]
          }
          let bblob = new Blob(blobarray, { type: "audio/mpeg" })
      
          //img.file("final.mp3",bblob)
          zip.file(csvFile.files[0].name.split(".")[0]+".mp3",bblob)
          zip.generateAsync({type:"blob"}).then(function(content) {      
              FileSaver.saveAs(content, "vocablistening.zip");
          });
      
        
      }
      
  
   
  
  
      //title: Vocabulary to Audio converter
      //metadescription: Easily convert vocabulary list into audio for flexible and efficient learning.
      //h1: Vocabulary to Audio Converter
      //Language Input;
      var LanguageOut = "English";
      var LanguageIn = "German";
      var voicein ="Marlene";
      var voiceout ="Emma";
      let LanIn =document.getElementById("LanguageInput")
      let LanOut =document.getElementById("LanguageOutput")
      LanIn.addEventListener("click", changeLanguagein)
      LanOut.addEventListener("click", changeLanguageout)
    
      function changeLanguageout(){
        LanguageOut = LanOut.options[LanOut.selectedIndex].value

        document.getElementById("slider2-h3").innerText="Break after "+LanguageOut
        document.getElementById("slider4-h3").innerText=LanguageOut+ " Voice Speed"
        //console.log("Language Out: "+LanguageOut)
        languagetovoice(LanguageOut,"out")
        //console.log("VoiceOut: "+voiceout)
      }
      function changeLanguagein(){
        LanguageIn = LanIn.options[LanIn.selectedIndex].value

        document.getElementById("slider1-h3").innerText="Break after "+LanguageIn
        document.getElementById("slider3-h3").innerText=LanguageIn+ " Voice Speed"
        //console.log("Language In: "+LanguageIn)
        languagetovoice(LanguageIn,"in")
        //console.log("VoiceIn: "+voicein)


      }
      function languagetovoice(llanguage,voice){
        let tempvoice;
        if (llanguage=="German"){
          tempvoice = "Marlene"
        } else if(llanguage=="English"){
          tempvoice = "Emma"
        } else if(llanguage=="French"){
          tempvoice = "Lea"
        }
        if (voice=="in"){
          voicein=tempvoice
        }else if (voice=="out"){
          voiceout=tempvoice
        }
      }
      AWS.config.accessKeyId=ALIAOYMFICM5P6IS96MW
      AWS.config.secretAccessKey=L2L9PlI2tNZ95x7LDAyNt3cP9SVmCqUdLORAI6N
      AWS.config.region="eu-west-1"
      var polly = new AWS.Polly();
      var params = {
          OutputFormat: "mp3",
          Text: "hello Tobias, how are you?",
          TextType: "ssml",
          VoiceId: "Joanna"
        };
      
  }
  
  

  
}
</script>
<!-- My colour palette colour1-blue: rgb(0,64,194), colour2-black: rgb(71, 69, 84) colour 3-lightgrey:rgb(172, 169, 187)-->
<style scoped>

@media(min-width: 740px){



.grid-container{
  color: rgb(71, 69, 84);
  

  margin: 0px;
  padding: 0px;
  
  /*background: rgba(172, 169, 187,0.2);*/

  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-template-areas: 
      "title title title title"
      "upload upload video video"
      "list list video video"
      "list list item11 item22"
      "list list item33 item44"
      "test test download download"
 
}}


@media(max-width: 739px){



.grid-container{
  color: rgb(71, 69, 84);
  height: 100vh;

  margin: 0px;
  padding: 0px;
  
  /*background: rgba(172, 169, 187,0.2);*/

  
  width: 95vw;
  display: grid;
  grid-template-columns: 1fr;
  
  /*grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;*/
  grid-row-gap: 20px;
  grid-template-areas: 
      "title"
      "video"
      "upload"
      "list"
      "list"
      "list"
      "item11" 
      "item22"
      "item33" 
      "item44"
      "test"
      "download"
 
}}
.grid-item{
  
  background: rgba(255, 255, 255, 0.97);
  border-radius: 15px;
  font-size: 20px;
  margin: 5px;
  padding: 5px;
  text-align: center;
  
 
  
}
.h1a{
  margin: 0px;
  padding: 0px;
}
h3{
  margin: 0px;
  padding:0px;
  font-size: 18px;
  font-weight: normal;
}
.grid-item-1{
 background:rgba(0, 64, 194, 0.2); 
 
 font-weight: bold;
 font-size: 20px;
 text-align: left;
 padding: 2px 2px 2px 50px;

}




.btn-item{
  height: 30px;
  border: none;
  background: rgba(0, 64, 194,0.7);
  color: rgba(255, 255, 255, 1);
  font-weight: bold;


  border-radius: 5px;
  font-size: 20px;
  
  padding: 0px 5px 0px 5px 0px;
  text-align: center;
}

.grid-item-1{
  padding: 20px;
  grid-area: title;


  
}
.grid-item-2{
  

  
  background: none;

  grid-area: upload;
  display: grid;
  grid-template-columns: 1fr;
  
  grid-template-rows: 1fr 1fr;
  
  grid-template-areas: 
      "input-grid-1"
      "input-grid-2"
    

  
}


.input-grid-1{
  grid-area:"input-grid-1";
  display: flex;
  align-items: flex-end;
  justify-content: center;

  
}

.input-grid-2{
  grid-area:"input-grid-2";
}
.grid-item-3{
  grid-area: video;
  background: none;
}
.grid-item-4{
  display: flex;
  justify-content: center;
  min-height: 150px;
  grid-area: list;
  
}
.grid-item-5{
  grid-area: item11;
 
  
  
}
.grid-item-6{
  grid-area: item22;
}
.grid-item-7{
  grid-area: item33;
}
.grid-item-8{
  grid-area: item44;
}
.grid-item-9{
  grid-area: test;
  display: flex;
  justify-content: center;
  background: none;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 
      "input-grid-3"
     
}
.input-grid-3{
  grid-area:"input-grid-3";
  display: flex;
  
  justify-content: center;
}
.grid-item-10{

  display: flex;
  justify-content: center;

  color: rgba(0, 64, 194,1);
  font-weight: bold;
  grid-area: download;
  background: none;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 
      "input-grid-4"
}

.input-grid-4{
  grid-area:"input-grid-4";
  display: flex;
  
  justify-content: center;

  
}




.slider1 {
display: flex;
align-items: center;
justify-content: center;
}

.slider1 input[type="range"] {
height: 2px;
background: black;
border: none;
outline: none;
}



.slider2 {
justify-content: center;
display: flex;
align-items: center;
}

.slider2 input[type="range"] {
height: 2px;
background: black;
border: none;
outline: none;
}



.slider3 {
display: flex;
align-items: center;
justify-content: center;
}

.slider3 input[type="range"] {
height: 2px;
background: black;
border: none;
outline: none;
}


.slider4 {
display: flex;
align-items: center;
justify-content: center;
}

.slider4 input[type="range"] {
height: 2px;
background: black;
border: none;
outline: none;
}



img{
  max-width:100%;
  max-height:100%;
}




#LanguageInput{
  border: none;
}
#LanguageOutput{
  border: none;
}


#thead{
  margin-right: 30px;
  display: flex;
  justify-content: center;
}
.btncsv1{
  border-radius: 5px 0px 0px 5px;
  padding: 3px;
}
.btncsv2{
  border-radius: 0px 5px 5px 0px;
  padding: 3px;
}
.textselect{
  height: 100%;
  display: flex;
  
  align-items: center;

  
}
.btntextelement{
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  font-size: 20px;
}

.testsettingtext{
  align-items: center;
  display: flex;
}

</style>